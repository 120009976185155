import { ComponentProps } from "react";
import Icon from "../icons";
import { TouchableOpacity } from "./styled";
import clsx from "clsx";

export default function ClickableIcon(props: ComponentProps<typeof Icon>) {
  const { icon, size, onPress, className, ...iconProps } = props;
  return (
    <TouchableOpacity
      onPress={onPress}
      className={clsx(className, "rounded-md bg-gray-100 p-1")}
    >
      <Icon icon={icon} size={size} {...iconProps} />
    </TouchableOpacity>
  );
}
